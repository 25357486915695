import { LogoIcon } from '@/components';
import { KeyActive, PageLayout } from '@/layouts/shared';
import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

const Custom404Error = () => {
  return (
    <>
      <div className="container tw-mx-auto md:tw-border-t tw-border-primary-main tw-pt-8 tw-pb-10 tw-text-primary-main">
        <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-flex-col tw-items-center tw-mt-20">
          <LogoIcon width="300" height="200" />
          <h1 className="tw-text-4xl">404 - Page Not Found</h1>
        </div>
      </div>
    </>
  );
};

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  return {
    props: {
      layout: PageLayout.New,
      isShowNav: false,
      keyActive: KeyActive.GuideMeasuring,
      ...(await serverSideTranslations(locale as string, ['layout'])),
    },
  };
};
export default Custom404Error;
